import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import ArrowIcon from '../ArrowIcon';
import { joinContent } from '../../content/index';
import Image from '../Image';
import joinBackgroundWebp from '../../../static/join/hero/background.webp';
import joinBackgroundPng from '../../../static/join/hero/background.png';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const Hero = ({ viewRef }: BodyPropsType) => {
  const { header, cards, image } = joinContent.hero;

  const handleTileClick: (id: string) => MouseEventHandler = (id: string) => {
    const tag = id.startsWith('#') ? id.substr(1) : id;
    return event => {
      event.preventDefault();
      const selector = document.getElementById(tag);
      if (selector) {
        selector.scrollIntoView({ behavior: 'smooth' });
      }
    };
  };

  return (
    <div className="relative w-full h-full">
      <Image
        src={joinBackgroundPng}
        webpSrc={joinBackgroundWebp}
        alt={`${image.desktop.alt}`}
        className="absolute w-full h-full object-cover object-left md:object-top z-negative"
      />
      <div
        ref={viewRef}
        data-testid="hero-container"
        className={classNames(
          'h-520px px-6',
          'md:h-580px md:px-11',
          'backface-hidden',
        )}
      >
        <div
          className={classNames(
            'pt-11 text-2xl font-extrabold text-white leading-normal',
            'md:pt-11.5 md:text-4xl',
          )}
        >
          <div className="md:hidden">
            <h2>{header.mobile.line1}</h2>
            <h2>{header.mobile.line2}</h2>
          </div>
          <div className="hidden md:block">
            <h2>{header.desktop.line1}</h2>
            <h2>{header.desktop.line2}</h2>
          </div>
        </div>
        <div className="hidden lg:flex gap-0.5 mt-12 w-auto h-auto transition-all transform xl:-translate-y-3">
          {cards.map((card, index, array) => {
            return (
              <button
                data-testid="hero-cards"
                onClick={handleTileClick(card.tag)}
                key={card.title}
                className={classNames(
                  'flex flex-col justify-between group bg-black bg-opacity-60 w-44 h-12 p-3 text-background relative border border-gray border-opacity-0 transition-all duration-300',
                  'xl:w-52 xl:h-44 text-left',
                  'pointer-fine:hover:border-white pointer-fine:hover:border-opacity-20 pointer-fine:hover:bg-black-400 pointer-fine:hover:bg-opacity-50',
                  index === array.length - 1 && 'bg-secondary bg-opacity-90',
                )}
              >
                <div>
                  <div className="py-1">{card.title}</div>
                  <div className="text-sm font-extralight">
                    {card.description}
                  </div>
                </div>
                <div className=" w-3 xl:w-4 block absolute right-4 bottom-3">
                  <ArrowIcon
                    direction="right"
                    className=" fill-current pointer-fine:group-hover:fill-secondary transform duration-300"
                  />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hero;
