import React, { useState, MouseEventHandler } from 'react';
import classNames from 'classnames';
import SideImageContainer from '../SideImageContainer';
import ArrowIcon from '../ArrowIcon';
import { joinContent } from '../../content/index';

const Testimonials = () => {
  const { header, image, cards } = joinContent.testimonials;

  const [cardIndex, setCardIndex] = useState(0);
  const [isButtonDisabled, seIsButtonDisabled] = useState(false);

  const { length } = cards;

  const previousIndex = (((cardIndex - 1) % length) + length) % length; // note that -x % n = -(x % n) and (x + n) % n = x % n for x ≥ 0
  const nextIndex = (cardIndex + 1) % cards.length;

  const animationDuration = 700;

  const buttonTimeOut = () => {
    seIsButtonDisabled(true);
    setTimeout(() => seIsButtonDisabled(false), animationDuration);
  };

  const handleLeftArrow: MouseEventHandler = event => {
    event.preventDefault();
    buttonTimeOut();
    setCardIndex(previousIndex);
  };
  const handleRightArrow: MouseEventHandler = event => {
    event.preventDefault();
    buttonTimeOut();
    setCardIndex(nextIndex);
  };

  return (
    <div data-testid="testimonials-container">
      <SideImageContainer
        imageContainerClassName="md:w-56% md:h-auto h-375px"
        imageClassName="object-cover md-900px:object-center object-center-right-65%"
        textClassName="top-0 left-0 md:p-64px lg:p-68px p-25px lg:text-2xl md:text-lg text-white font-bold"
        imagePath={`../join/testimonials/${image.file}`}
        imageAlt={image.alt}
      >
        <div className="flex flex-grow place-content-center mt-6 mx-6 md:m-8 lg:m-9">
          <div className="flex flex-grow flex-col place-content-center gap-28px md:gap-0">
            <div className="flex flex-col gap-28px md:h-500px">
              <div className="text-xl md:text-3xl font-bold text-center md:text-left">
                {header}
              </div>
              <div className="md:block hidden md:w-200px lg:w-320px xl:w-full md:font-semibold md:text-lg h-auto">
                {cards[cardIndex].name}
              </div>
              <div className="relative h-220px 2xs:h-200px md:h-260px text-sm sm-400px:text-lg overflow-hidden">
                {cards.map((card, index) => (
                  <div
                    data-testid="quote-text"
                    key={card.name}
                    className={classNames(
                      'absolute',
                      'w-full',
                      'transition-all',
                      'transform',
                      'ease-in-out',
                      `duration-${animationDuration}`,
                      {
                        'translate-x-0': index === cardIndex,
                        'opacity-100': index === cardIndex,
                        invisible: index !== cardIndex,
                        'opacity-0': index !== cardIndex,
                        'translate-x-full': index === nextIndex,
                        '-translate-x-full': index === previousIndex,
                      },
                    )}
                  >{`"${card.quote}"`}</div>
                ))}
              </div>
            </div>
            <div className="flex justify-between mx-auto w-full max-w-500px">
              <button
                className="w-25px min-w-25px h-3 self-center"
                onClick={handleLeftArrow}
                disabled={isButtonDisabled}
              >
                <ArrowIcon
                  direction="left"
                  className="fill-current pointer-fine:hover:fill-secondary"
                />
              </button>
              <div className="md:hidden text-base text-center px-2 font-semibold">
                {cards[cardIndex].name}
              </div>
              <button
                className="w-25px min-w-25px h-3 self-center"
                onClick={handleRightArrow}
                disabled={isButtonDisabled}
              >
                <ArrowIcon
                  direction="right"
                  className="fill-current pointer-fine:hover:fill-secondary"
                />
              </button>
            </div>
          </div>
        </div>
      </SideImageContainer>
    </div>
  );
};

export default Testimonials;
