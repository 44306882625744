import * as React from 'react';
import content from '../../content/join.yaml';
import PageSection from '../../components/PageSection';
import ScrollTag from '../../components/ScrollTag';
import Testimonials from '../../components/join/Testimonials';
import Internship from '../../components/join/Internship';
import Benefits from '../../components/join/Benefits';
import Hero from '../../components/join/Hero';
import Teams from '../../components/join/Teams';
import Culture from '../../components/join/Culture';
import Apply from '../../components/join/Apply';
import DocumentHead from '../../components/DocumentHead';

type BodyPropsType = {
  viewRef: (node?: Element | null | undefined) => void;
};

const JoinPage = ({ viewRef }: BodyPropsType) => {
  return (
    <div data-testid="join-container">
      <DocumentHead content={content.metadata} />
      <div className="mb-8" data-testid="hero">
        <Hero viewRef={viewRef} />
      </div>

      <PageSection className="mb-9 mx-3 lg:mx-68px md:mx-64px" testId="culture">
        <ScrollTag id="culture" />
        <Culture />
      </PageSection>

      <PageSection
        className="md:mb-11 mb-9 mx-3 lg:mx-68px md:mx-64px"
        testId="benefits"
      >
        <ScrollTag id="benefits" />
        <Benefits />
      </PageSection>

      <PageSection
        className="md:mb-11 mb-9"
        testId="testimonials"
        justifyClass="flex justify-center"
      >
        <ScrollTag id="testimonials" />
        <Testimonials />
      </PageSection>

      <PageSection
        className="lg:mb-11 md:mb-9 mb-7 lg:mx-68px md:mx-64px sm:mx-5 mx-2.5"
        testId="teams"
      >
        <ScrollTag id="teams" />
        <Teams />
      </PageSection>

      <PageSection
        testId="apply"
        hasDefaultBackground
        data-testid="applyButton"
      >
        <ScrollTag id="apply" />
        <Apply />
      </PageSection>

      <PageSection testId="internship">
        <Internship />
      </PageSection>
    </div>
  );
};

export default JoinPage;
