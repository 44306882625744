import React from 'react';
import classNames from 'classnames';
import { joinContent } from '../../content/index';
import Image from '../Image';

const { header, sections } = joinContent.culture;

type CultureTilePropsType = {
  className?: string;
  cultureCard:
    | typeof sections[number]['topCard']
    | typeof sections[number]['bottomCard'];
};

const CultureTile = ({ className, cultureCard }: CultureTilePropsType) => {
  return (
    <div
      data-testid="cultureCard"
      key={cultureCard.image.alt}
      className={`flex gap-4 bg-opacity-30 h-full ${className}`}
    >
      <Image
        alt={cultureCard.image.alt}
        src={`../../../join/culture/${cultureCard.image.file}`}
        className="flex-1 rounded-md min-w-0 min-h-0 object-cover md:max-w-150px lg:max-w-none lg:flex-none"
      />
      <div className="flex-1">
        <h2 className="text-sm font-semibold my-2 md:text-base lg:text-lg">
          {cultureCard.title}
        </h2>
        <p className="text-sm font-light md:text-xs lg:text-sm xl:text-base">
          {cultureCard.description}
        </p>
      </div>
    </div>
  );
};

function Culture() {
  return (
    <div data-testid="cultureContainer">
      <h1
        data-testid="cultureHeader"
        className="mb-8 px-3 text-base font-semibold font-sans text-lg md:text-xl md:text-center md:text-2xl"
      >
        {header}
      </h1>
      <div className="md:space-y-4 space-y-3">
        {sections.map((section, sectionIndex) => (
          <div
            data-testid="cultureSection"
            key={section.desktop.image.alt}
            className={classNames(
              'flex gap-4',
              sectionIndex % 2 !== 0 && 'md:flex-row-reverse',
            )}
          >
            <div className="md:flex hidden flex-1 ">
              <Image
                src={`../../../join/culture/${section.desktop.image.file}`}
                webpSrc={`../../../join/culture/${section.desktop.image.webpFile}`}
                alt={section.desktop.image.alt}
                className="rounded-md object-cover h-full"
              />
            </div>
            <div className="flex flex-col flex-1 space-y-3 md:space-y-0 divide-gray-300 md:divide-y">
              <CultureTile className="md:pb-3" cultureCard={section.topCard} />
              <CultureTile
                className="md:pt-3 flex-row-reverse md:flex-row"
                cultureCard={section.bottomCard}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Culture;
