import React from 'react';
import ContentImageTile from '../ContentImageTile';
import List from '../List';
import { joinContent } from '../../content/index';

const Teams = () => {
  const { header, cards } = joinContent.teams;

  return (
    <div data-testid="teams-container" className="md-900px:space-y-9 space-y-7">
      <h1 className="md:text-32px lg:font-extrabold font-bold text-center text-17px">
        {header}
      </h1>
      <div className="flex md:grid lg:grid-cols-3 md:grid-cols-2 lg:grid-rows-2 md:grid-rows-3 flex-col lg:gap-x-8 md:gap-10 sm:gap-5 lg:gap-y-9 gap-y-7">
        {cards.map(card => (
          <div
            data-testid="team-card-container"
            key={card.title}
            className="w-full justify-self-center md:w-auto"
          >
            <ContentImageTile
              imagePath={`../join/teams/${card.image.file}`}
              alt={card.image.alt}
              className="aspect-w-1 aspect-h-1"
            />
            <div className="uppercase font-semibold my-3 text-17px md:text-18px">
              {card.title}
            </div>
            <div className="text-12px font-light md:text-14px">
              {card.description}
            </div>
            {card.points && (
              <List
                className="mt-1 ml-3 pl-4"
                itemClassName="ml-2.5 text-sm font-light text-black text-12px md:text-14px"
                items={card.points}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Teams;
