import React, { UIEventHandler, useState, useRef } from 'react';
import classNames from 'classnames';
import ScrollArrows from '../ScrollArrows';
import { joinContent } from '../../content';
import ContentImageTile from '../ContentImageTile';

type CardType = {
  title: string;
  description: string;
  image: { file: string; alt: string };
};

function Benefits() {
  const [onScroll, setOnScroll] = useState<
    UIEventHandler<HTMLDivElement> | undefined
  >();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tileRef = useRef<HTMLDivElement | null>(null);

  return (
    <div data-testid="benefitsContainer">
      <h1
        data-testid="benefitsTitle"
        className="md:text-center font-bold font-sans tracking-tight text-xl md:text-2xl xl:text-32px"
      >
        {joinContent.benefits.header}
      </h1>
      <div className="flex">
        <div
          ref={containerRef}
          onScroll={onScroll}
          className={classNames(
            'mx-auto',
            'flex mt-7 md:mt-9 overflow-x-scroll overflow-y-hidden scrollbar-none scroll-smooth',
            'md:grid md:grid-cols-3 gap-6 lg:gap-9 ',
          )}
        >
          {joinContent.benefits.cards.map((item: CardType) => {
            return (
              <div
                ref={tileRef}
                data-testid="benefitCard"
                className="flex flex-col md:block"
                key={item.title}
              >
                <ContentImageTile
                  imagePath={`../../../join/benefits/${item.image.file}`}
                  className="scale-150 mx-auto"
                  imageClassName="object-contain lg:h-100px"
                  alt={item.image.alt}
                />
                <div className="mt-4 self-center md:h-76.5px md-846px:h-51px xl-1287px:h-27px flex place-items-center">
                  <h2 className="text-17px text-center min-w-250px md:min-w-0 font-semibold lg:text-18px w-full">
                    {item.title}
                  </h2>
                </div>
                <div>
                  <p className="font-light text-xs mt-2.5 lg:text-sm md:text-sm">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ScrollArrows
        setOnScrollState={setOnScroll}
        elementGap={40}
        scrollContainer={containerRef.current}
        sampleElement={tileRef.current}
        className="w-full my-5 px-4"
        scrollContainerClass="justify-center flex md:hidden"
        scrollType="visibleElements"
      />
    </div>
  );
}

export default Benefits;
