import React from 'react';
import classNames from 'classnames';
import ContentImageTile from '../ContentImageTile';
import emailIconWhite from '../../assets/contact/email-icon-white.png';
import emailIconBlack from '../../assets/contact/email-icon-black.png';
import { joinContent } from '../../content/index';

const Internship = () => {
  const { header, description, image, email, subject } = joinContent.internship;

  const buttonClass = classNames(
    'md:px-4 px-19px py-15px',
    'border border-black rounded',
    'bg-secondary pointer-fine:hover:bg-white',
    'border-opacity-0 pointer-fine:hover:border-opacity-100',
    'text-white pointer-fine:hover:text-black',
    'transform duration-200 ease-in group',
  );

  return (
    <div
      data-testid="internship-container"
      className="flex md:flex-row flex-col h-750px md:h-428px lg:h-540px mt-11 md:mb-11"
    >
      <ContentImageTile
        className="md:rounded-none h-full w-full"
        imageClassName="object-cover object-center rounded"
        textClassName="md:hidden bottom-5 right-5 text-white font-semibold"
        imagePath={`../join/internship/${image.file}`}
        alt={image.alt}
      />
      <div className="flex flex-col md:mx-6 md:mt-9 mt-7 xl:mt-11 mb-10 md:w-9/12 lg:w-5/12 self-center">
        <div className="text-center md:text-left mx-auto md:m-0 mb-5 md:mb-6 text-lg md:text-2xl font-semibold md:font-bold">
          {header}
        </div>
        <div className="flex md:mx-0 md:mb-7 mb-6 mx-auto md:text-base text-sm md:text-left md:w-full w-8/12">
          {description}
        </div>
        <div
          data-testid="internship-email-container"
          className="flex justify-center"
        >
          <a href={`mailto:${email}?subject=${subject}`}>
            <button className={buttonClass}>
              <div className="flex justify-center items-center space-x-3">
                <div className="flex-none relative">
                  <img
                    className="absolute object-contain opacity-0 transform duration-200 ease-in pointer-fine:group-hover:opacity-100"
                    src={emailIconBlack}
                    alt="Envelope"
                  />
                  <img src={emailIconWhite} alt="Envelope" />
                </div>
                <div className="text-sm font-semibold">{email}</div>
              </div>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Internship;
